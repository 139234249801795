import { ColorsConfig } from 'components/ds/Progress/progress.types';

export function getCurrentIndicatorColor(value: number, colors?: ColorsConfig) {
  const DEFAULT_COLOR = 'rgb(var(--primary-base))';

  if (colors == null) return DEFAULT_COLOR;

  const sortedColors = Object.keys(colors)
    .map(Number)
    .sort((a, b) => a - b);
  const closestColorKey = sortedColors.reduce(
    (previousColorKey, currentColorKey) => {
      // Calculate the difference between the current color key and the value
      const differenceForCurrentColorKey = Math.abs(value - currentColorKey);

      // Calculate the difference between the previous color key and the value
      const differenceForPreviousColorKey = Math.abs(value - previousColorKey);

      // If the difference for the current color key is less than the difference for the previous color key,
      // it means the current color key is closer to the value, so return the current color key.
      // Otherwise, return the previous color key.
      return differenceForCurrentColorKey < differenceForPreviousColorKey
        ? currentColorKey
        : previousColorKey;
    }
  );
  return colors[closestColorKey] ?? DEFAULT_COLOR;
}
