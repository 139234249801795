import { InfiniteData } from '@tanstack/react-query';
import { createInfiniteDataTransform } from 'util/createInfiniteDataTransform';

export function addFullNameToDataItems<
  TData extends { id: number; firstName: string; lastName: string },
>(data: InfiniteData<{ items: Array<TData> }, number>) {
  return createInfiniteDataTransform(data, (item) => {
    return {
      ...item,
      name: `${item.firstName} ${item.lastName}`,
    };
  });
}
