import * as ProgressPrimitive from '@radix-ui/react-progress';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { getCurrentIndicatorColor } from './progress.helpers';

export type ColorsConfig = Record<number, string>;
interface ProgressProps
  extends React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> {
  colors?: ColorsConfig;
}
export const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  ProgressProps
>(({ className, value, colors, children, ...rest }, ref) => {
  return (
    <span
      className="flex items-center gap-2"
      style={
        {
          '--progress-indicator-color': getCurrentIndicatorColor(
            value ?? 0,
            colors
          ),
        } as React.CSSProperties
      }
    >
      <ProgressPrimitive.Root ref={ref} asChild {...rest}>
        <span
          className={twMerge(
            'relative block h-2 w-full overflow-hidden rounded-full bg-ds-bg-soft',
            className
          )}
        >
          <ProgressPrimitive.Indicator asChild>
            <span
              className="block h-full w-full flex-1 rounded-full bg-[var(--progress-indicator-color,rgb(var(--bg-surface)))] transition-all ease-in-out"
              style={{ transform: `translateX(-${100 - (value ?? 0)}%)` }}
            />
          </ProgressPrimitive.Indicator>
        </span>
      </ProgressPrimitive.Root>
      {children}
    </span>
  );
});
Progress.displayName = ProgressPrimitive.Root.displayName;

export function ProgressLabel({
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      className={twMerge(
        'font-regular m-0 text-left text-xs text-ds-text-secondary',
        className
      )}
      {...rest}
    />
  );
}
