import { MODULE_ICON } from 'components/layout-v2/constants/module-icon.constants';

export function getNavItems(roles: string[]) {
  const isAdmin = roles.includes('admin');

  return {
    breakline: [
      {
        label: 'Dashboard',
        icon: MODULE_ICON.home,
        to: '/',
        end: true,
      },
      {
        icon: MODULE_ICON.pipelinePlus,
        label: 'Pipeline +',
        to: '/pipeline-plus',
        end: false,
      },
      {
        label: 'Participants',
        icon: MODULE_ICON.participants,
        to: '/participants',
        end: false,
      },
      {
        label: 'Partners',
        icon: MODULE_ICON.partners,
        to: '/partners',
        end: false,
      },
      {
        label: 'Roles',
        icon: MODULE_ICON.roles,
        to: '/jobs',
        end: false,
      },
      {
        label: 'Create Shortlist',
        icon: MODULE_ICON.shortlist,
        to: '/shortlist',
        end: false,
      },
      {
        label: 'Hired',
        icon: MODULE_ICON.hired,
        to: '/hired',
        end: false,
      },
      {
        label: 'Resources',
        icon: MODULE_ICON.resources,
        to: '/content-library',
        end: false,
      },
      {
        label: 'Assignments',
        icon: MODULE_ICON.assignments,
        to: '/assignments',
        end: false,
      },
      {
        label: 'Surveys',
        icon: MODULE_ICON.surveys,
        to: '/surveys',
        end: false,
      },
      {
        label: 'Events',
        icon: MODULE_ICON.events,
        to: '/events',
        end: false,
      },
      {
        label: 'Automation Hub',
        icon: MODULE_ICON.automation,
        to: '/automation',
        end: false,
      },
      ...(isAdmin
        ? [
            {
              label: 'Tags',
              icon: MODULE_ICON.tags,
              to: '/tags',
              end: false,
            },
            {
              label: 'Team',
              icon: MODULE_ICON.team,
              to: '/team',
              end: false,
            },
          ]
        : []),
    ],
    recruiter: [
      {
        label: 'Dashboard',
        icon: MODULE_ICON.home,
        to: '/',
        end: true,
      },
      {
        label: 'Breakliners',
        icon: MODULE_ICON.team,
        to: '/candidates',
        end: false,
      },
      {
        label: 'Roles',
        icon: MODULE_ICON.roles,
        to: '/jobs',
        end: false,
      },
      {
        label: 'Insights',
        icon: MODULE_ICON.resources,
        to: '/content-library',
        end: false,
      },
    ],
    participant: [
      {
        label: 'Dashboard',
        icon: MODULE_ICON.home,
        to: '/',
        end: true,
      },
      {
        label: 'Partners',
        icon: MODULE_ICON.partners,
        to: '/partners',
        end: false,
      },
      {
        label: 'Roles',
        icon: MODULE_ICON.roles,
        to: '/jobs',
        end: false,
      },
      {
        label: 'Interviews',
        icon: MODULE_ICON.interviews,
        to: '/interviews',
        end: false,
      },
      {
        label: 'Game Plans',
        icon: MODULE_ICON.gameplans,
        to: '/game-plans',
        end: false,
      },
      {
        label: 'Resources',
        icon: MODULE_ICON.resources,
        to: '/content-library',
        end: false,
      },
      {
        label: 'Assignments',
        icon: MODULE_ICON.assignments,
        to: '/assignments',
        end: false,
      },
      {
        label: 'Events',
        icon: MODULE_ICON.events,
        to: '/events',
        end: false,
      },
    ],
  } as const;
}
