import '@reach/menu-button/styles.css';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'api/client';
import { Amplify } from 'aws-amplify';
import { IconSprite } from 'components/ds/icons/Icon';
import { LoadingContainer } from 'components/ds/Spinner';
import { Toaster } from 'components/ds/Toast/Toast';
import { RootError } from 'components/Error';
import Maintenance from 'components/Maintenance';
import { AuthProvider } from 'context/Auth';
import { posthogClient } from 'modules/common/posthog/api/posthog-client';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  RouterProvider,
  ScrollRestoration,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { PrivateRoutes } from 'views/private/PrivateRoutes';
import { PublicRoutes } from 'views/public/PublicRoutes';
import Symbols from './components/svg/Symbols';
import TabFocusOutlineStyles from './components/TabFocusOutlineStyles';
import config from './config';
import GlobalStyle from './styles/global-style';

Amplify.configure({
  Auth: {
    region: config.cognito.region,
    userPoolId: config.cognito.poolID,
    userPoolWebClientId: config.cognito.appID,
  },
});

if (config.sentry.dsn) {
  Sentry.init({
    dsn: config.sentry.dsn,
    environment: process.env.NODE_ENV,
    attachStacktrace: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          /^https:\/\/(dev-api|api|app)\.breakline\.org/,
        ],
      }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.5,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/(dev-api|api|app)\.breakline\.org/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 1.0 : 0,
  });
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootRoute />,
    children: [
      ...PublicRoutes(queryClient),
      ...PrivateRoutes(queryClient, posthogClient),
    ],
  },
]);

function RootRoute() {
  return (
    <AuthProvider>
      <div>
        <Outlet />
        <ScrollRestoration />
      </div>
    </AuthProvider>
  );
}

function App() {
  if (
    Number.parseInt(config.maintenanceMode ?? '', 10) === 1 &&
    window.location.search !== `?mcode=${config.maintenanceModeCode}`
  ) {
    return (
      <>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          defaultTitle="BreakLine"
          titleTemplate="%s | BreakLine"
          meta={[{ charSet: 'UTF-8' }, { name: 'robots', content: 'noindex' }]}
        />
        <Maintenance />
        <GlobalStyle />
      </>
    );
  }
  return (
    <RootError>
      <React.Suspense fallback={<LoadingContainer level="full" />}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          defaultTitle="BreakLine"
          titleTemplate="%s | BreakLine"
          meta={[{ charSet: 'UTF-8' }, { name: 'robots', content: 'noindex' }]}
        />
        <QueryClientProvider client={queryClient}>
          <Symbols />
          <IconSprite />
          <RouterProvider router={router} />

          <TabFocusOutlineStyles />

          <GlobalStyle />

          <Toaster />
          <ReactQueryDevtools position="left" initialIsOpen={false} />
        </QueryClientProvider>
      </React.Suspense>
    </RootError>
  );
}

export default App;
