import { posthog } from 'posthog-js';

export const posthogClient = getPosthogClient();

function getPosthogClient() {
  if (!process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
    return;
  }

  return posthog.init(
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
    {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      person_profiles: 'identified_only',
      autocapture: false,
      disable_session_recording: true,
    },
    'breakline_platform_frontend'
  );
}
