import { Logomark } from 'components/ds/Logomark';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import { tv, VariantProps } from 'tailwind-variants';

export interface AvatarProps extends VariantProps<typeof avatarStyles> {
  className?: string;
  children?: React.ReactNode;
}
export function Avatar({
  size,
  type,
  color,
  className,
  children,
}: AvatarProps) {
  const { container } = avatarStyles({ size, type, color });

  return (
    <AvatarContext.Provider value={{ size, type }}>
      <span className="block">
        <span className={container({ className })}>{children}</span>
      </span>
    </AvatarContext.Provider>
  );
}

export const AvatarImage = React.forwardRef<
  HTMLImageElement,
  React.ComponentPropsWithoutRef<'img'>
>(({ className, alt, ...rest }, ref) => {
  return (
    <img
      {...rest}
      className={twMerge(
        'aspect-square h-full w-full overflow-clip object-cover object-center',
        className
      )}
      alt={alt ?? ''}
      ref={ref}
    />
  );
});
AvatarImage.displayName = 'AvatarImage';

interface AvatarFallbackProps extends VariantProps<typeof avatarStyles> {
  fallback: string[];
}
export function AvatarFallback({ size, fallback }: AvatarFallbackProps) {
  const context = React.useContext(AvatarContext);
  const sz = size ?? context.size;
  const initials = getInitialsFromFallback(fallback ?? []);

  return (
    <span
      className={twMerge(
        sz === 'xl' ? 'text-base' : 'text-xs',
        'select-none font-medium tabular-nums leading-none text-ds-text-primary'
      )}
    >
      {sz !== 'sm' ? initials : initials?.charAt(0)}
    </span>
  );
}

export function AvatarSystemFallback({
  size,
}: VariantProps<typeof avatarStyles>) {
  const context = React.useContext(AvatarContext);
  const sz = size ?? context.size;
  const { icon } = avatarStyles({ size: sz });

  return <Logomark className={icon()} />;
}

/**
 * Gets the first letter of each string in the array and returns them as a
 * string.
 *
 * @param fallbackArr - An array of strings.
 * @returns The initials as a string.
 */
export function getInitialsFromFallback(fallbackArr: string[]) {
  if (!fallbackArr || fallbackArr.length === 0) return '';

  return fallbackArr
    .map((string) => string?.charAt(0))
    .join('')
    .toUpperCase();
}

const avatarStyles = tv(
  {
    slots: {
      container: 'overflow-clip grid place-items-center bg-ds-teal-lighter',
      icon: '',
    },
    variants: {
      color: {
        default: { container: 'bg-ds-teal-lighter' },
        secondary: { container: 'bg-ds-secondary-lighter' },
        system: {
          container: 'bg-ds-bg-weaker border border-ds-stroke-tertiary',
        },
      },
      type: {
        circle: {
          container: 'rounded-full',
        },
        square: {
          container: 'rounded-md',
        },
      },
      size: {
        xl: {
          container: 'h-16 w-16',
          icon: 'h-6 w-6',
        },
        lg: {
          container: 'h-10 w-10',
          icon: 'h-5 w-5',
        },
        base: {
          container: 'h-8 w-8',
          icon: 'h-4 w-4',
        },
        sm: {
          container: 'h-6 w-6',
          icon: 'h-3 w-3',
        },
      },
    },
    defaultVariants: {
      size: 'base',
      type: 'circle',
      color: 'default',
    },
  },
  {
    responsiveVariants: true,
  }
);

const AvatarContext = React.createContext<VariantProps<typeof avatarStyles>>({
  size: 'base',
  type: 'circle',
});

interface UserAvatarProps
  extends Pick<AvatarProps, 'size' | 'color' | 'className'> {
  headshot: string | null;
  alt?: string;
  fallback?: string[];
}
export function UserAvatar({
  size,
  color,
  headshot,
  alt,
  fallback = [],
  className,
}: UserAvatarProps) {
  const isSystemFallback = headshot == null && fallback.length === 0;

  return (
    <Avatar
      size={size}
      color={isSystemFallback ? 'system' : color}
      className={className}
    >
      <>
        {(() => {
          switch (true) {
            case headshot != null: {
              return (
                <AvatarImage src={headshot!} alt={alt ?? fallback.join(' ')} />
              );
            }
            case fallback.length > 0: {
              return <AvatarFallback fallback={fallback} />;
            }
            default: {
              return <AvatarSystemFallback />;
            }
          }
        })()}
      </>
    </Avatar>
  );
}

interface CompanyAvatarProps extends Pick<AvatarProps, 'size'> {
  logo?: string | null;
  alt?: string;
  fallback?: string[];
}
export function CompanyAvatar({
  size,
  logo,
  alt,
  fallback = [],
}: CompanyAvatarProps) {
  return (
    <Avatar size={size} type="square" className="bg-ds-bg-foundation">
      <>
        {(() => {
          switch (true) {
            case logo != null: {
              return (
                <AvatarImage src={logo!} alt={alt ?? fallback.join(' ')} />
              );
            }
            case fallback.length > 0: {
              return <AvatarFallback fallback={fallback} />;
            }
            default: {
              return <AvatarSystemFallback />;
            }
          }
        })()}
      </>
    </Avatar>
  );
}
