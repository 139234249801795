import { useLogout, useUserProfile } from 'api/user/user';
import { Icon, IconType, IconUse } from 'components/ds/icons/Icon';
import { toast } from 'components/ds/Toast/Toast';
import {
  Tooltip,
  TooltipContent,
  TooltipGroup,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ds/Tooltip';
import { getNavItems } from 'components/layout-v2/helpers/navigation.helpers';
import { navAction } from 'components/layout-v2/styles/layout.styles';
import * as React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

interface NavMenuProps {
  isOpen?: boolean;
  onLinkSelection?: (href: string) => void;
}
export function NavMenu({ isOpen, onLinkSelection }: NavMenuProps) {
  const { data: user } = useUserProfile();
  const logoutMutation = useLogout();
  const navigate = useNavigate();
  const { container, text } = navAction({
    state: isOpen ? 'expanded' : 'collapsed',
  });

  return (
    <div className="relative h-[calc(100dvh_-_var(--siteheader-height))] min-h-0 w-full stack-y-3 lg:h-full">
      <div className="scrollbar-none min-h-0 flex-1 overflow-y-auto p-5 stack-y-3">
        <p className="m-0 p-1 text-xs font-medium uppercase text-ds-text-tertiary">
          Main
        </p>
        <TooltipGroup>
          <ul className="m-0 min-h-0 list-none p-0 stack-y-2">
            {user != null &&
              getNavItems(user.roles)[user.type].map((item) => {
                return (
                  <li className="relative" key={item.label}>
                    <NavItemTooltip label={item.label} isOpen={isOpen}>
                      {/**
                       * Ref and props will be forwarded to the `NavItemLink` when the sidebar is
                       * collpased (!isOpen). This makes the `NavItemLink` the trigger element for the
                       * Tooltip
                       */}
                      <NavItemLink
                        href={item.to}
                        icon={item.icon}
                        end={item.end}
                        isOpen={isOpen}
                        onLinkSelection={onLinkSelection}
                      >
                        {item.label}
                      </NavItemLink>
                    </NavItemTooltip>
                  </li>
                );
              })}
          </ul>
        </TooltipGroup>
      </div>
      <div className="sticky bottom-0 w-full border-t border-solid border-ds-stroke-tertiary bg-ds-bg-foundation p-5">
        <button
          className={container({
            theme: 'outlined',
          })}
          onClick={() => {
            logoutMutation.mutate(undefined, {
              onSuccess: () => {
                navigate('/login', { replace: true });
              },
              onError: () => {
                toast.error('Logout was unsuccessful');
              },
            });
          }}
        >
          <div className={twMerge('items-center stack-x-2')}>
            <Icon
              aria-hidden
              className={isOpen ? 'translate-x-0' : 'translate-x-1/2'}
            >
              <IconUse id="logout-box-line" />
            </Icon>
            <span className={text()}>Logout</span>
          </div>
        </button>
      </div>
    </div>
  );
}

interface NavItemLinkProps {
  href: string;
  icon?: IconType;
  end?: boolean;
  isOpen?: boolean;
  children?: React.ReactNode;
  onLinkSelection?: (href: string) => void;
}
const NavItemLink = React.forwardRef<HTMLAnchorElement, NavItemLinkProps>(
  ({ href, end, icon, children, isOpen, onLinkSelection, ...rest }, ref) => {
    const { container, text } = navAction({
      state: isOpen ? 'expanded' : 'collapsed',
    });

    return (
      <NavLink
        to={href}
        end={end}
        onClick={() => {
          onLinkSelection?.(href);
        }}
        className={({ isActive }) => {
          return container({
            className: twMerge(
              'no-underline',
              isActive ? 'bg-ds-bg-weaker' : ''
            ),
          });
        }}
        ref={ref}
        {...rest}
      >
        {({ isActive }) => {
          return (
            <>
              <span
                aria-hidden
                className="pointer-events-none absolute -left-5 top-1/2 block h-5 w-1 -translate-y-1/2"
              >
                <span
                  className={twMerge(
                    'block h-full w-full rounded-br rounded-tr bg-ds-primary-base transition-all',
                    isActive
                      ? 'translate-x-0 opacity-100'
                      : '-translate-x-full opacity-0'
                  )}
                />
              </span>
              <span className={twMerge('items-center stack-x-2')}>
                {icon != null ? (
                  <Icon
                    preserveAspectRatio="xMidYMid meet"
                    aria-hidden
                    className={twMerge(
                      'grid flex-shrink-0 place-items-center transition-colors',
                      isActive ? 'text-ds-primary-base' : '',
                      isOpen ? 'translate-x-0' : 'translate-x-1/2'
                    )}
                  >
                    <IconUse id={icon} />
                  </Icon>
                ) : (
                  <span
                    aria-hidden
                    className="pointer-events-none block h-5 w-5"
                  />
                )}
                <span className={text()}>{children}</span>
              </span>
            </>
          );
        }}
      </NavLink>
    );
  }
);
NavItemLink.displayName = 'NavItemLink';

interface NavItemTooltipProps {
  label: string;
  isOpen?: boolean;
  children?: React.ReactNode;
}
function NavItemTooltip({ label, isOpen, children }: NavItemTooltipProps) {
  /**
   * We only want to render the tooltip when the sidebar is collapsed. If the
   * sidebar is open, we simply render the children without the tooltip related
   * components.
   */
  return !isOpen ? (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent sideOffset={10} side="right">
          {label}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <>{children}</>
  );
}
