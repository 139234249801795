import { twMerge } from 'tailwind-merge';

export function GlassCard({
  children,
  outerClassName,
  innerClassName,
  ...rest
}: Omit<React.ComponentPropsWithoutRef<'div'>, 'className'> & {
  outerClassName?: string;
  innerClassName?: string;
}) {
  return (
    <div
      className={twMerge(
        'w-full overflow-hidden rounded-xl border-4 border-ds-stroke-tertiary/40 ring-1 ring-ds-stroke-tertiary backdrop-blur-sm',
        outerClassName
      )}
      {...rest}
    >
      <div className={twMerge('bg-ds-bg-foundation/95', innerClassName)}>
        {children}
      </div>
    </div>
  );
}
