import { Button, ButtonIcon } from 'components/ds/Button';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { tv } from 'tailwind-variants';

export function ProgressButtonContainer({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={progressButtonVariants().container({
        className,
      })}
    >
      {children}
    </div>
  );
}
export function ProgressButtonContent({
  direction = 'left',
  children,
}: {
  direction: 'left' | 'right';
  children?: React.ReactNode;
}) {
  return (
    <>
      <Icon className="h-4 w-4">
        <IconUse
          id={direction === 'left' ? 'arrow-left-s-line' : 'arrow-right-s-line'}
        />
      </Icon>
      <span className="sr-only">
        {children != null ? children : direction === 'left' ? 'Back' : 'Next'}
      </span>
    </>
  );
}

export const progressButtonVariants = tv({
  slots: {
    container:
      'inline-flex items-center rounded-full border border-ds-stroke-tertiary bg-ds-bg-foundation shadow-sm',
    button:
      'relative flex h-8 w-9 items-center justify-center text-ds-text-secondary transition-colors hover:bg-ds-bg-weaker aria-disabled:cursor-not-allowed aria-disabled:text-ds-text-secondary/60',
  },
  variants: {
    direction: {
      left: {
        button:
          'rounded-l-full before:absolute before:right-0 before:h-4 before:w-[1px] before:bg-ds-stroke-tertiary before:content-[""] before:inset-y-center',
      },
      right: {
        button: 'rounded-r-full',
      },
    },
  },
});

export function NextStepButton({
  children,
  ...rest
}: React.ComponentProps<typeof Button>) {
  return (
    <Button
      suffix={
        <ButtonIcon>
          <IconUse id="arrow-right-line" />
        </ButtonIcon>
      }
      {...rest}
    >
      <span className="inline-flex items-baseline gap-2">
        Next Step
        <span className="font-normal empty:hidden">{children}</span>
      </span>
    </Button>
  );
}
