import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...rest }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={twMerge('group flex flex-col gap-2', className)}
      {...rest}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...rest }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={twMerge(
        'group peer aspect-square h-4 w-4 rounded-full border border-ds-stroke-tertiary bg-transparent shadow-sm ring-offset-2 transition-colors focus-within:bg-ds-bg-weaker hover:border-ds-stroke-secondary hover:bg-ds-bg-weaker focus:outline-none focus-visible:border-ds-stroke-secondary focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ds-stroke-secondary disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...rest}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <div className="h-2 w-2 rounded-full bg-ds-primary-base transition-colors group-hover:bg-ds-primary-dark" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;
