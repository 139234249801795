import { Progress } from 'components/ds/Progress/Progress';
import { Icon, IconUse } from 'components/ds/icons/Icon';
import { GlassCard } from 'modules/participant/utah/components/GlassCard';
import { SplitLayoutPortal } from 'modules/participant/utah/components/SplitLayout';
import {
  StepId,
  UTAH_PROGRAM_AVAILABLE_STEPS,
} from 'modules/participant/utah/utah.constants';
import * as React from 'react';
import { tv } from 'tailwind-variants';

export function StepProgress() {
  return (
    <div className="flex gap-2 p-3">
      {Object.entries(UTAH_PROGRAM_AVAILABLE_STEPS)
        .filter(([key]) => key !== 'welcome' && key !== 'commitment')
        .map(([id, step]) => {
          return <Step key={id} id={id as StepId} step={step} />;
        })}
    </div>
  );
}

function Step({
  id,
  step,
}: {
  id: StepId;
  step: (typeof UTAH_PROGRAM_AVAILABLE_STEPS)[StepId];
}) {
  const { state } = useStepProgress();
  const status = (() => {
    switch (true) {
      case state.currentStep === step.step: {
        return 'active';
      }
      case state.currentStep > step.step: {
        return 'complete';
      }
      default: {
        return 'inactive';
      }
    }
  })();
  const progress = (() => {
    switch (true) {
      case state.currentStep > step.step: {
        return 100;
      }
      case state.currentStep === step.step: {
        return state.currentProgress;
      }
      default: {
        return 0;
      }
    }
  })();
  const { text, stepNumber } = stepVariants({
    status,
  });

  return (
    <div className="flex flex-1 flex-col gap-4">
      <div className="flex items-center gap-2">
        <span className={stepNumber()}>
          {status === 'complete' ? (
            <Icon className="h-4 w-4">
              <IconUse id="checkbox-check" />
            </Icon>
          ) : (
            step.step
          )}
        </span>
        <span className={text()}>{STEP_LABELS[id]}</span>
      </div>
      <Progress value={progress} />
    </div>
  );
}
const stepVariants = tv({
  slots: {
    text: 'text-sm font-normal',
    stepNumber:
      'flex h-5 w-5 items-center justify-center rounded-full text-center text-xs font-medium leading-none shadow-sm',
  },
  variants: {
    status: {
      complete: {
        stepNumber: 'bg-ds-primary-base text-ds-text-inverse border-none',
        text: 'text-ds-text-secondary',
      },
      inactive: {
        stepNumber:
          'bg-ds-bg-foundation text-ds-text-secondary border border-ds-stroke-tertiary',
        text: 'text-ds-text-secondary',
      },
      active: {
        stepNumber: 'bg-ds-neutral-400 text-ds-text-inverse',
        text: 'text-ds-text-primary',
      },
    },
  },
  defaultVariants: {
    status: 'inactive',
  },
});

const STEP_LABELS = {
  welcome: 'Welcome',
  'basic-info': 'Info',
  demographics: 'Self-ID',
  taxonomy: 'Skills',
  bonus: 'Bonus',
  commitment: 'Commit',
} satisfies Record<StepId, string>;

export function StepProgressLayout({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <>
      <SplitLayoutPortal>
        <div className="flex h-full w-full flex-col gap-5">
          <div className="px-8 pt-8">
            <GlassCard>
              <StepProgress />
            </GlassCard>
          </div>
          <div className="flex-1">{children}</div>
        </div>
      </SplitLayoutPortal>
      <div className="pb-6 lg:hidden">
        <StepProgress />
      </div>
    </>
  );
}

interface StepProgressState {
  currentStep: number;
  currentProgress: number;
}
type StepProgressAction =
  | {
      type: 'UPDATE_STEP';
      value: number;
    }
  | {
      type: 'UPDATE_PROGRESS';
      value: number;
    };

const INITIAL_STATE: StepProgressState = {
  currentStep: 1,
  currentProgress: 0,
};
const StepProgressContext = React.createContext<{
  state: StepProgressState;
  dispatch: React.Dispatch<StepProgressAction>;
} | null>(null);

export function StepProgressProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(
    stepProgressReducer,
    INITIAL_STATE
  );

  return (
    <StepProgressContext.Provider value={{ state, dispatch }}>
      {children}
    </StepProgressContext.Provider>
  );
}

export function useStepProgress() {
  const context = React.useContext(StepProgressContext);
  if (!context) {
    throw new Error(
      'useStepProgress must be used within a StepProgressProvider'
    );
  }
  return context;
}

function stepProgressReducer(
  state: StepProgressState,
  action: StepProgressAction
): StepProgressState {
  switch (action.type) {
    case 'UPDATE_STEP': {
      return {
        ...state,
        currentStep: action.value,
        currentProgress: 0,
      };
    }
    case 'UPDATE_PROGRESS': {
      return {
        ...state,
        currentProgress: action.value,
      };
    }
    default: {
      return state;
    }
  }
}
