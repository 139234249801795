export const formLabelStyles = 'text-base' as const;
export const inputStyles = 'text-base h-12' as const;
export const errorMessageStyles = 'text-base' as const;
export const formRowStyles =
  'flex w-full flex-wrap gap-x-6 gap-y-6 max-w-prose flex-col' as const;
export const formItemStyles = 'w-full min-w-[280px] flex-1' as const;
export const checkboxStyles = 'h-5 w-5' as const;
export const radioStyles = 'h-5 w-5' as const;
export const selectionGroupStyles =
  'flex w-full min-w-[280px] flex-1 !flex-row items-center gap-3' as const;
