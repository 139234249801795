import { Logo } from 'components/ds/Logo';
import { LoadingContainer } from 'components/ds/Spinner';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';

export function SplitLayout({ children }: { children: React.ReactNode }) {
  return (
    <SplitLayoutPortalProvider>
      <div className="[--site-header-height:58px] lg:[--site-header-height:71px]">
        <header className="fixed left-0 top-0 flex h-[var(--site-header-height)] w-full items-center justify-between gap-5 bg-ds-bg-foundation px-5 lg:w-1/2 lg:px-6">
          <Logo className="max-w-[150px]" aria-hidden theme="dark" />
        </header>
        <main className="min-h-[100dvh]">
          <div className="flex h-full w-full">
            <div className="flex w-full pt-[var(--site-header-height)] lg:w-1/2 2xl:w-2/3">
              <div className="flex h-full max-h-[calc(100dvh-var(--site-header-height))] min-h-[max(calc(100dvh-var(--site-header-height)),calc(500px+var(--site-header-height)))] w-full items-center">
                <div className="flex h-full w-full overflow-y-auto overflow-x-clip">
                  <React.Suspense
                    fallback={
                      <LoadingContainer className="w-full" level="component" />
                    }
                  >
                    {children}
                  </React.Suspense>
                </div>
              </div>
            </div>
            <div className="hidden w-1/2 lg:flex 2xl:w-1/3">
              <div className="w-full py-3 pr-3">
                <div className=" relative flex h-full w-full items-center justify-center overflow-hidden rounded-lg">
                  <img
                    aria-hidden
                    alt=""
                    src="/images/utah-onboarding-painting.webp"
                    rel="preload"
                    className="absolute inset-0 z-0 h-full w-full object-cover"
                  />
                  <div className="relative z-10 flex h-full w-full flex-col gap-5">
                    <SplitLayoutRight />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SplitLayoutPortalProvider>
  );
}

export function SplitLayoutLeft({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={twMerge('flex h-full flex-col', className)}>{children}</div>
  );
}

export function SplitLayoutLeftContent({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'flex flex-1 flex-col gap-6 overflow-y-auto overflow-x-visible px-5',
        className
      )}
    >
      {children}
    </div>
  );
}

export function SplitLayoutLeftFooter({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        'sticky bottom-0 left-0 flex flex-wrap items-center justify-between gap-5 bg-ds-bg-foundation pb-10 pt-5',
        className
      )}
    >
      {children}
    </div>
  );
}

function SplitLayoutRight() {
  const [, setSplitLayoutPortal] =
    React.useContext(SplitLayoutPortalContext) ?? [];

  return (
    <div className="h-full w-full empty:hidden" ref={setSplitLayoutPortal} />
  );
}

const SplitLayoutPortalContext = React.createContext<
  | [
      HTMLDivElement | null,
      React.Dispatch<React.SetStateAction<HTMLDivElement | null>>,
    ]
  | null
>(null);

export function SplitLayoutPortalProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const splitLayoutPortalState = React.useState<HTMLDivElement | null>(null);

  return (
    <SplitLayoutPortalContext.Provider value={splitLayoutPortalState}>
      {children}
    </SplitLayoutPortalContext.Provider>
  );
}

export function SplitLayoutPortal({ children }: { children: React.ReactNode }) {
  const [portalContentElement] =
    React.useContext(SplitLayoutPortalContext) ?? [];

  return portalContentElement
    ? ReactDOM.createPortal(children, portalContentElement)
    : null;
}
